import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { BookingStatus, BookingStatuses, getBookingStatusValue } from 'core/constants/booking-status';
import { CustomerStatus, getCustomerStatusNameFromKey } from 'core/constants/customer-status';
import { getLeadTypeNameFromKey, LeadType, LeadTypes } from 'core/constants/lead-type';
import { FilterType } from 'core/enums/filter-type';
import { useDialog } from 'core/providers/DialogProvider';
import { createAddressString } from 'core/utilities/address-string-builder';
import { secondsToDate, secondsToDateString } from 'core/utilities/date-helpers';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import dayjs from 'dayjs';
import { CustomersSlice } from 'modules/customers/customers-slice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ParamKeyValuePair, useNavigate, useSearchParams } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import SharedFilters, { ISharedFilterField } from 'shared/components/filters/filters';
import SharedTable from 'shared/components/table/table';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import BookingBreakdownDialog from '../booking-breakdown-dialog/booking-breakdown-dialog';

const awaitingBookingTableColumns = [
  { label: 'Customer name', key: 'fullName', templateId: 'text', width: 10 },
  { label: 'Customer age', key: 'age', templateId: 'text', width: 10 },
  { label: 'Customer location', key: 'location', templateId: 'text', width: 15 },
  { label: 'Lead type', key: 'leadType', templateId: 'text', width: 10 },
  { label: 'Customer status', key: 'customerStatus', templateId: 'text', width: 15 },
  { label: 'Booking status', key: 'bookingStatus', templateId: 'text', width: 15 },
  { label: 'Status updated', key: 'lastContact', templateId: 'text', width: 15 },
  { label: '', key: 'action', templateId: 'action', width: 10 },
];

interface IAwaitingBooking {
  userRoles: string[];
}

const AwaitingBooking = ({ userRoles }: IAwaitingBooking) => {
  const [filterValues, setFilterValues] = useState<StringIndexable>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = useDialog();

  useEffect(() => {
    setFilterValues({});
    searchParams.forEach((value, key) => {
      setFilterValues((prevState) => {
        return { ...prevState, [key]: value };
      });
    });
  }, [searchParams]);

  const testsStatusMap: StringIndexable = {
    booker: [
      CustomerStatus.AWAITING_TEST_BOOKING,
      CustomerStatus.TEST_CANCELLED_REARRANGE,
      CustomerStatus.AWAITING_FITTING_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      CustomerStatus.FITTING_CANCELLED_REARRANGE,
      CustomerStatus.AWAITING_SERVICE_BOOKING,
      CustomerStatus.SERVICE_CANCELLED_REARRANGE,
    ],
    uniqueBooker: [CustomerStatus.NOT_INTERESTED, CustomerStatus.TESTED_NOT_PRESCRIBED, CustomerStatus.TEST_CANCELLED],
    specialisedBooker: [CustomerStatus.AWAITING_TEST_BOOKING, CustomerStatus.TEST_CANCELLED_REARRANGE],
  };

  const otherStatusMap: StringIndexable = {
    booker: [
      CustomerStatus.AWAITING_FITTING_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      CustomerStatus.FITTING_CANCELLED_REARRANGE,
      CustomerStatus.AWAITING_SERVICE_BOOKING,
      CustomerStatus.SERVICE_CANCELLED_REARRANGE,
    ],
  };

  const leadTypeMap: StringIndexable = {
    booker: [
      LeadType.CAMPAIGN,
      LeadType.SURVEY,
      LeadType.SHOP_VISIT,
      LeadType.PHONE_IN,
      LeadType.FACEBOOK,
      LeadType.DIGITAL,
    ],
    uniqueBooker: LeadTypes.map((type) => type.value),
    specialisedBooker: [
      LeadType.MVF,
      LeadType.PRIZE_DRAW,
      LeadType.PRISM,
      LeadType.NMG,
      LeadType.FACEBOOK,
      LeadType.DIGITAL,
    ],
  };

  const isBookerCustomer = (customer: IStoredCustomer) => {
    return (
      otherStatusMap.booker.includes(customer.status) ||
      (testsStatusMap.booker.includes(customer.status) && leadTypeMap.booker.includes(customer.leadType))
    );
  };

  const twelveMonthsAgo = dayjs(new Date()).subtract(12, 'month').toDate();

  const isUniqueCustomer = (customer: IStoredCustomer) => {
    return (
      testsStatusMap.uniqueBooker.includes(customer.status) &&
      leadTypeMap.uniqueBooker.includes(customer.leadType) &&
      secondsToDate(customer.statusUpdatedAt) < twelveMonthsAgo
    );
  };

  const isSpecialisedCustomer = (customer: IStoredCustomer) => {
    return (
      testsStatusMap.specialisedBooker.includes(customer.status) &&
      leadTypeMap.specialisedBooker.includes(customer.leadType)
    );
  };

  const roleMap: StringIndexable = {
    booker: [isBookerCustomer],
    uniqueBooker: [isUniqueCustomer],
    specialisedBooker: [isSpecialisedCustomer],
    admin: [isBookerCustomer, isUniqueCustomer, isSpecialisedCustomer],
    superAdmin: [isBookerCustomer, isUniqueCustomer, isSpecialisedCustomer],
  };

  const filterArray = userRoles
    .map((role) => roleMap[role])
    .filter((func) => func)
    .flat();

  const navigate = useNavigate();
  const customers = useSelector(CustomersSlice.selectAll);

  const getCustomerLocation = (customer: IStoredCustomer) => {
    if (customer.address) {
      const { town_or_city, postcode } = customer.address;
      return createAddressString([town_or_city, postcode]);
    }

    return customer.mvfCounty ?? 'Check notes';
  };

  const filteredCustomers = customers
    .filter((customer) => {
      if (
        customer.bookingStatus === BookingStatus.CALLBACK_LATER &&
        dayjs() <= dayjs(customer.callback.date).startOf('day')
      ) {
        return false;
      }

      return (
        filterArray.some((func) => func(customer)) &&
        Object.keys(filterValues)
          .filter((key) => isNotNullOrEmpty(filterValues[key]))
          .every((key) => {
            if (!filterValues[key]) {
              return true;
            }

            if (key === 'bookingStatus' && filterValues[key].includes('awaitingFirstCall') && !customer.bookingStatus) {
              return true;
            }

            if (key === 'createdDate') {
              return filterValues[key] === dayjs(secondsToDate(customer.createdAt)).format('YYYY-MM-DD');
            }

            if (key === 'location') {
              const location = getCustomerLocation(customer).toLowerCase();
              return location.includes(filterValues[key].toLowerCase());
            }

            return !filterValues[key] || filterValues[key].split(',').includes(customer[key]);
          })
      );
    })
    .sort((a, b) => a.statusUpdatedAt - b.statusUpdatedAt);
  const actionCellTemplate = (customer: IStoredCustomer) => {
    return (
      <div className='w-full flex justify-end'>
        <SharedButton
          onClick={() => navigate(`/customers/${customer.uid}`)}
          type='button'
          appearance='link'
          label='View'
        />
      </div>
    );
  };

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: actionCellTemplate,
      id: 'action',
    },
  ];

  const getCustomersForStatus = (statuses: string[]) =>
    filteredCustomers.filter((customer) => statuses.some((status) => status === customer.status));

  const counts = [
    {
      key: 'test',
      label: 'Awaiting a test booking:',
      customers: getCustomersForStatus([CustomerStatus.TEST_CANCELLED_REARRANGE, CustomerStatus.AWAITING_TEST_BOOKING]),
    },
    {
      key: 'fitting',
      label: 'Awaiting a fitting booking:',
      customers: getCustomersForStatus([
        CustomerStatus.FITTING_CANCELLED_REARRANGE,
        CustomerStatus.AWAITING_FITTING_BOOKING,
        CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      ]),
    },
    {
      key: 'service',
      label: 'Awaiting a service booking:',
      customers: getCustomersForStatus([
        CustomerStatus.AWAITING_SERVICE_BOOKING,
        CustomerStatus.SERVICE_CANCELLED_REARRANGE,
      ]),
    },
  ];

  const availableStatuses = Array.from(
    new Set(
      Object.keys(roleMap).flatMap((role) => {
        const hasRole = userRoles.includes(role);
        switch (role) {
          case 'admin':
          case 'superAdmin':
            return hasRole ? [...Object.values(testsStatusMap).flat(), ...Object.values(otherStatusMap).flat()] : [];
          default:
            return hasRole ? [...testsStatusMap[role], ...(otherStatusMap[role] ?? [])] : [];
        }
      })
    )
  );

  const filters: ISharedFilterField[] = [
    {
      label: 'Customer status',
      key: 'status',
      filterType: FilterType.CheckboxGroup,
      checkboxes: availableStatuses.map((key) => ({
        title: getCustomerStatusNameFromKey(key),
        key,
      })),
    },
    {
      label: 'Booking status',
      key: 'bookingStatus',
      filterType: FilterType.CheckboxGroup,
      checkboxes: [
        {
          key: 'awaitingFirstCall',
          title: 'Awaiting First Call',
        },
        ...BookingStatuses.map((bs) => ({
          title: bs.label,
          key: bs.key,
        })),
      ],
    },
    {
      key: 'leadType',
      placeholder: 'Lead type',
      options: LeadTypes,
      filterType: FilterType.CheckboxGroup,
      checkboxes: LeadTypes.map((type) => ({
        title: type.label,
        key: type.value,
      })),
      label: 'Lead type',
    },
    {
      key: 'createdDate',
      filterType: FilterType.DatePicker,
      label: 'Created date',
    },
    {
      key: 'location',
      filterType: FilterType.TextField,
      label: 'Customer location',
    },
  ];

  const handleFilterChange = (key: string, value: string) => {
    if (!value) {
      searchParams.delete(key);
      setSearchParams(searchParams, { replace: true });
    } else {
      const newValues: ParamKeyValuePair[] = [];
      searchParams.forEach((currentVal, currentKey) => {
        if (currentKey !== key) {
          newValues.push([currentKey, currentVal]);
        }
      });
      newValues.push([key, value]);
      setSearchParams(newValues);
    }
  };

  return (
    <div>
      <div className='grid grid-cols-1 md:flex border-b body-02 mb-4'>
        {counts.map((count) => (
          <button
            onClick={() =>
              dialog?.openDialog(<BookingBreakdownDialog customers={count.customers} title={count.label} />)
            }
            key={count.key}
            className='text-left py-4 md:mr-4 last:mr-0 pr-4 last:pr-0 border-0 border-b md:border-b-0 last:border-0 md:border-r'
          >
            {count.label} <span className='font-semibold'>{count.customers.length}</span>
          </button>
        ))}
      </div>
      <SharedFilters
        filterInputs={filters}
        filterValues={filterValues}
        changeHandler={handleFilterChange}
        gridStyles='grid-cols-4 gap-6'
      />
      <SharedTable
        columns={awaitingBookingTableColumns}
        rows={filteredCustomers.map((customer) => ({
          key: customer.uid,
          data: {
            ...customer,
            age: isNotNullOrEmpty(customer.age) ? customer.age : 'Not provided',
            customerStatus: getCustomerStatusNameFromKey(customer.status),
            bookingStatus: getBookingStatusValue(customer, customer.bookingStatus),
            lastContact: `${secondsToDateString(customer.statusUpdatedAt)} \nby ${
              customer.statusUpdatedBy?.fullName ?? 'Unknown'
            }`,
            leadType: getLeadTypeNameFromKey(customer.leadType),
            location: getCustomerLocation(customer),
          },
        }))}
        emptyText={'There are no customers awaiting booking'}
        customTemplates={customTemplates}
      />
    </div>
  );
};

export default AwaitingBooking;
