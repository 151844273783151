import { QuerySnapshot } from 'firebase/firestore';
import { useCallback } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import 'chart.js/auto';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import { ICustomer } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { getRandomColor } from 'core/utilities/color-helpers';
import { getLeadTypeNameFromKey } from 'core/constants/lead-type';
import ReportBreakdownCustomCell, {
  IReportBreakdownCustomCell,
} from '../report-breakdown-custom-cell/report-breakdown-custom-cell';
import { CustomerStatus, CustomerStatuses } from 'core/constants/customer-status';
import { BookingStatuses } from 'core/constants/booking-status';

const CustomerLeadTypeStatus = () => {
  const hasABookingStatus = useCallback(
    (status: string) =>
      [
        CustomerStatus.AWAITING_FITTING_BOOKING,
        CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
        CustomerStatus.AWAITING_TEST_BOOKING,
        CustomerStatus.TEST_CANCELLED_REARRANGE,
        CustomerStatus.FITTING_CANCELLED_REARRANGE,
        CustomerStatus.FITTED_FOLLOW_UP,
        CustomerStatus.AFTER_CARE_CALL,
      ].some((s) => s === status),
    []
  );

  const processRows = useCallback(
    async (querySnapshot: QuerySnapshot<ICustomer>) => {
      const rows: IReportBaseTableRows<IReportBreakdownCustomCell>[] = [];
      querySnapshot.forEach((doc) => {
        const customer = doc.data();
        const { leadType } = customer;
        const customerListRow = {
          customer: doc.data(),
        };
        const leadTypeIndex = rows.findIndex((row) => row.key === leadType);
        if (leadTypeIndex !== -1) {
          rows[leadTypeIndex].data.count += 1;
          const statusIndex = rows[leadTypeIndex].data.breakdowns.findIndex((type) => type.key === customer.status);
          if (statusIndex !== -1) {
            rows[leadTypeIndex].data.breakdowns[statusIndex].count += 1;
            rows[leadTypeIndex].data.breakdowns[statusIndex].customerListRows?.push(customerListRow);
            if (rows[leadTypeIndex].data.breakdowns[statusIndex].breakdowns) {
              const bookingStatusIndex = rows[leadTypeIndex].data.breakdowns[statusIndex].breakdowns?.findIndex(
                (bs) => bs.key === (customer.bookingStatus ?? 'noAttempts')
              );
              if (bookingStatusIndex !== undefined && bookingStatusIndex !== -1) {
                rows[leadTypeIndex].data.breakdowns[statusIndex].breakdowns![bookingStatusIndex].count += 1;
                rows[leadTypeIndex].data.breakdowns[statusIndex].breakdowns![bookingStatusIndex].customerListRows?.push(
                  customerListRow
                );
              }
            }
          }
        } else {
          rows.push({
            key: leadType,
            color: getRandomColor(),
            data: {
              label: getLeadTypeNameFromKey(leadType),
              count: 1,
              breakdowns: CustomerStatuses.map((status) => ({
                key: status.key,
                count: customer.status === status.key ? 1 : 0,
                label: status.label,
                customerListRows: customer.status === status.key ? [customerListRow] : [],
                ...(hasABookingStatus(status.key) && {
                  breakdowns: [
                    {
                      label: 'Awaiting First Call',
                      key: 'noAttempts',
                    },
                    ...BookingStatuses,
                  ].map((bs) => ({
                    key: bs.key,
                    count:
                      customer.status === status.key && (customer.bookingStatus ?? 'noAttempts') === bs.key ? 1 : 0,
                    label: bs.label,
                    customerListRows:
                      customer.status === status.key && (customer.bookingStatus ?? 'noAttempts') === bs.key
                        ? [customerListRow]
                        : [],
                  })),
                }),
              })),
            },
          });
        }
      });
      return rows;
    },
    [hasABookingStatus]
  );

  const tableColumns = [{ label: 'Lead type', key: 'breakdown', templateId: 'breakdown', width: 50 }];

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: ReportBreakdownCustomCell,
      id: 'breakdown',
    },
  ];

  return (
    <>
      <PageHeader title='Customer lead type status report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeDate: before,
          afterDate: after,
        })}
        subscriber={CustomersApiService.subscribeToCustomers}
        totalLabel={'Total number of customers created'}
        emptyText='No customers created during the selected time frame'
        customTemplates={customTemplates}
      />
    </>
  );
};

export default CustomerLeadTypeStatus;
