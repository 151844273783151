import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import { IUser } from 'core/api/users/users-api-interface';
import { AppointmentType, getAppointmentStyle } from 'core/constants/appointment-type';
import { useCallback, useEffect, useState } from 'react';
import SharedCalendarAppointment from '../calendar-appointment/calendar-appointment';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { useFlags } from '@atlaskit/flag';
import TimelinePreviewDialog from 'modules/appointments/timeline-preview-dialog/timeline-preview-dialog';
import { AppointmentStatus } from 'core/constants/appointment-status';
import clsx from 'clsx';

interface ICalendarColumn {
  resource: IUser;
  highlightedResource?: string;
  newAppointment?: {
    start: string;
    end: string;
  };
  columnAppointments: IAppointment[];
  timeSlots: string[];
  showAppointmentMenu: boolean;
}

const CalendarColumn = ({
  resource,
  highlightedResource,
  newAppointment,
  columnAppointments,
  timeSlots,
  showAppointmentMenu,
}: ICalendarColumn) => {
  const [groups, setGroups] = useState<IAppointment[][][]>([]);
  const collides = useCallback((a: IAppointment, b: IAppointment) => {
    return a.end > b.start && a.start < b.end;
  }, []);
  const dialog = useDialog();
  const flags = useFlags();

  useEffect(() => {
    let g: IAppointment[][][] = [];
    let columns: IAppointment[][] = [];
    let lastEventEnding: string | undefined;
    columnAppointments
      .sort((a, b) => {
        if (a.start < b.start) return -1;
        if (a.start < b.start) return 1;
        if (a.end < b.end) return -1;
        if (a.end > b.end) return 1;
        return 0;
      })
      .forEach((e) => {
        // Check if a new event group needs to be started.
        if (lastEventEnding && e.start >= lastEventEnding) {
          // The event is later than any of the events in the
          // current group. There is no overlap. Output the
          // current event group and start a new one.
          g.push(columns);
          columns = [];
          lastEventEnding = undefined;
        }

        // Try to place the event inside an existing column.
        let placed = false;
        columns.some((col) => {
          if (!collides(col[col.length - 1], e)) {
            col.push(e);
            placed = true;
          }
          return placed;
        });

        // It was not possible to place the event (it overlaps
        // with events in each existing column). Add a new column
        // to the current event group with the event in it.
        if (!placed) columns.push([e]);

        // Remember the last event end time of the current group.
        if (!lastEventEnding || e.end > lastEventEnding) lastEventEnding = e.end;
      });
    g.push(columns);
    setGroups(g);
  }, [collides, columnAppointments]);

  const expand = (a: IAppointment, index: number, cols: IAppointment[][]) => {
    let colSpan = 1;
    cols.slice(index + 1).some((col) => {
      if (col.some((app) => collides(a, app))) return true;
      colSpan += 1;
      return false;
    });
    return colSpan;
  };

  const openRoute = () => {
    if (!showAppointmentMenu) {
      return;
    }

    if (!resource.address) {
      showErrorFlag(
        'Incomplete assignee',
        'The assignee does not have a home address assigned, please contact your administrator',
        flags
      );
      return;
    }

    const filteredAppointments = columnAppointments.filter(
      (app) => app.customer.address && app.status !== AppointmentStatus.CANCELLED && app.type !== AppointmentType.ADMIN
    );

    if (filteredAppointments.length === 0) {
      showErrorFlag('No appointments', 'There are no appointments to view', flags);
      return;
    }

    dialog?.openDialog(
      <TimelinePreviewDialog
        assigneeAddress={resource.address}
        appointments={filteredAppointments.map((app) => ({
          uid: app.uid,
          customerName: app.customer.fullName,
          address: app.customer.address!,
          type: app.type,
          start: app.start,
          end: app.end,
        }))}
      />
    );
  };

  return (
    <div
      className={clsx(
        'w-full border-r last:border-0',
        resource.uid === highlightedResource && 'bg-blue-50',
        resource.fullName === 'Holding Calendar' ? 'min-w-[600px]' : 'min-w-[200px]'
      )}
    >
      <div
        className={`w-full bg-gray-50 p-4 h-[56px] text-center font-semibold body-02 ${
          showAppointmentMenu && 'hover:bg-gray-100 cursor-pointer'
        } ${resource.isPride && 'text-primary'}`}
        onClick={openRoute}
      >
        {resource.fullName}
      </div>
      <div className='relative min-h-[432px]'>
        {newAppointment && highlightedResource === resource.uid && (
          <div
            className='absolute w-full rounded-md shadow-sm text-white z-20 p-2 overflow-y-auto bg-opacity-10'
            style={getAppointmentStyle(newAppointment.start, newAppointment.end, 'new', 'new', 1, 0)}
          >
            <p className='body-02 font-semibold'>New appointment</p>
            <p className='body-03'>
              {newAppointment.start} - {newAppointment.end}
            </p>
          </div>
        )}
        {groups.map((cols) =>
          cols.map((col, index) =>
            col.map((appointment) => (
              <SharedCalendarAppointment
                weekly={false}
                key={appointment.uid}
                appointment={appointment}
                widthPercent={expand(appointment, index, cols) / cols.length}
                leftPercent={index / cols.length}
                showMenu={showAppointmentMenu}
              />
            ))
          )
        )}
        {timeSlots.map((slot) => (
          <div className='h-[60px] relative border-t' key={slot}></div>
        ))}
      </div>
    </div>
  );
};

export default CalendarColumn;
